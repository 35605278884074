import { CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Landing from "./pages/Landing";
import { theme } from "./util/theme";

const initialOptions = {
  "client-id": "test",
  currency: "EUR",
  // intent: "capture",
  // "data-client-token": "abc123xyz==",
};

function InnerApp() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="about" element={<Landing />} />
    </Routes>
  );
}

function App() {
  return (
    <ThemeProvider
      theme={theme(
        useMediaQuery("(prefers-color-scheme: dark)") ? "dark" : "light"
      )}
    >
      <CssBaseline />
      <BrowserRouter>
        <PayPalScriptProvider options={initialOptions}>
          {/* <PayPalButtons style={{ layout: "horizontal" }} /> */}
          <InnerApp />
        </PayPalScriptProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
