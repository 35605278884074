import { createTheme } from "@mui/material/styles";

export const theme = (preferredType: "dark" | "light") =>
  createTheme({
    typography: {
      fontFamily: "Nunito",
    },
    palette: {
      mode: preferredType,
      primary: {
        main: "#E79115",
        contrastText: "#FFF",
      },
    },
  });
