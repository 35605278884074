import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useWindowDimensions } from "../util/util";

export default function Landing() {
  const windowDimensions = useWindowDimensions();
  return (
    <Container
      fixed
      style={{
        textAlign: "center",
        height: windowDimensions.height,
        overflowY: "hidden",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box style={{ margin: 20 }}>
        <Typography variant="h4" fontWeight="bold">
          TinyBots.shop is coming soon!
        </Typography>
      </Box>
      {/* <StlViewer
        width={windowDimensions.width}
        height={windowDimensions.height * 0.9}
        url="bot.stl"
        groundColor="rgb(255, 255, 255)"
        objectColor="rgb(50, 255, 255)"
        skyboxColor="rgb(255, 255, 255)"
        gridLineColor="rgb(0, 0, 0)"
        lightColor="rgb(255, 255, 255)"
        volume={setvolume}
      /> */}
      <div
        style={{
          background:
            "radial-gradient(circle, rgba(2,0,36,1) 0%, #ebd93955 5%, rgba(0,0,0,0) 40%, rgba(0,0,0,0) 100%)",
        }}
      >
        <img
          src={
            useMediaQuery("(prefers-color-scheme: dark)")
              ? "/animate-dark.gif"
              : "/animate-light.gif"
          }
          alt="Your next bot!"
          style={{
            height: "60vh",
            justifySelf: "flex-end",
            width: "fit-content",
          }}
        />
      </div>
    </Container>
  );
}
